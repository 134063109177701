{
    "ui": {
        "Branding": {
            "header": "Strumenti del vicinato",
            "subHeader": "Videoconferenza"
        },
        "Consent": {
            "paragraph1": "Acconsento all'utilizzo della videochiamata per consentire all'agente di supporto della comunità Ring di analizzare e risolvere il mio problema/interrogazione. Consultare l'Informativa sulla privacy di Ring per ulteriori informazioni.",
            "paragraph2": "Riconosco che si tratta di una videochiamata unidirezionale e che il video dell'agente non sarà disponibile. Capisco che lo schermo dell'agente non verrà condiviso durante la videochiamata.",
            "confirmButton": "Accetta",
            "declineButton": "Rifiuta"
        },
        "CameraDevices": {
            "cameraHeader": "Sorgente della telecamera",
            "cameraDropdown": "Nessun dispositivo di telecamera trovato",
            "previewHeader": "Anteprima video",
            "qualityHeader": "Qualità video",
            "qualityDropdown": "Seleziona la qualità video"
        },
        "DeviceSetup": {
            "header": "Impostazioni dispositivo"
        },
        "EndMeetingControls": {
            "endMeetingButton": "Annulla",
            "endMeetingLabel": "Esci",
            "endMeetingModal": "Termina riunioni",
            "endMeetingText": "Sei sicuro di voler terminare la riunione?"
        },
        "EndMeeting": {
            "header": "intestazione"
        },
        "MeetingControls": {
            "audioLabel": "Altoparlante",
            "menuLabel": "Menu",
            "muteLabel": "Silenzi",
            "unmuteLabel": "Anulla silenzio",
            "mutedIconTitle": "Microfono silenziato",
            "unmutedIconTitle": "Riattiva microfono",
            "voiceFocusOffLabel": "Abilita Amazon Voice Focus",
            "voiceFocusOnLabel": "Amazon Voice Focus abilitato"
        },
        "MeetingJoinDetails": {
            "component": {
                "joinMeetingButton": "Unisciti alla riunione",
                "joinMeetingText": "Partecipazione alla riunione come",
                "loadingText": "Caricamento in corso..."
            },
            "modal": {
                "modalHeaderText": "Impossibile aggiornare il link",
                "cardTitle": "Videoconferenza",
                "cardDescription": "La riunione è terminata",
                "cardSubtext1": "Redirigendo in",
                "cardSubtext2": "secondi ..."
            }
        },
        "MicrophoneActivity": {
            "microphoneActivityText": "Attività del microfono"
        },
        "MicrophoneDevices": {
            "microphoneText": "Sorgente del microfono",
            "microphoneDropdown": "Nessun dispositivo di microfono trovato"
        },
        "Permission": {
            "Denied": {
                "CameraMicModal": {
                    "Header": {
                        "Title": "Permesso per fotocamera e microfono negato"
                    },
                    "Body": {
                        "Title": "Devi abilitare Fotocamera e Microfono",
                        "Description": "Non abbiamo il permesso per abilitare la tua videocamera e il tuo microfono.",
                        "Hint": "Attiva le autorizzazioni per il microfono e la videocamera, quindi aggiorna la pagina."
                    }
                }
            },
            "InProgress": {
                "CameraMicModal": {
                    "Header": {
                        "Title": "Autorizzazioni fotocamera e microfono"
                    },
                    "Body": {
                        "Title": "Abilita i permessi per microfono e videocamera",
                        "Description": "Per chattare in video, abbiamo bisogno delle autorizzazioni per attivare il microfono e la videocamera.",
                        "Hint": "Nel popup, scegli Consenti."
                    }
                }
            }
        },
        "SpeakerDevices": {
            "speakerText": "Prueba altavoz",
            "sourceText": "Fuente de altavoz",
            "noSpeakerText": "No se ha encontrado ningún dispositivo con altavoz"
        },
        "VideoInputTransformation": {
            "blurLabel": "Habilitar desenfoque de fondo",
            "replacementLabel": "Habilitar reemplazo de fondo"
        },
        "VideoTransform": {
            "header": "Menú Desplegable de transformación de video"
        }
    }
}
