// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext } from 'react';

import { StyledWrapper, StyledAudioGroup, StyledVideoGroup, StyledAudioGroupWithoutVideo } from './Styled';
import MicrophoneDevices from './MicrophoneDevices/MicrophoneDevices';
import SpeakerDevices from './SpeakerDevices/SpeakerDevices';
import CameraDevices from './CameraDevices/CameraDevices';
import { VideoChatContext } from '../../../VideoChatNxUI';

const DeviceSelectionWithVideo = () => (
  <StyledWrapper>
    <StyledAudioGroup>
      <MicrophoneDevices />
      <SpeakerDevices />
    </StyledAudioGroup>
    <StyledVideoGroup>
        <CameraDevices />
    </StyledVideoGroup>
  </StyledWrapper>
);

const DeviceSelectionWithoutVideo = () => (
  <StyledAudioGroupWithoutVideo>
    <MicrophoneDevices />
    <SpeakerDevices />
  </StyledAudioGroupWithoutVideo>
);

const DeviceSelection = () => {
  const { enableVideoSharing } = useContext(VideoChatContext);
  
  return (enableVideoSharing ? <DeviceSelectionWithVideo /> : <DeviceSelectionWithoutVideo />);
};

export default DeviceSelection;
