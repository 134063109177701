// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React from 'react';

import { 
    useContentShareState,
    useFeaturedTileState,
    useLocalVideo,
    useRemoteVideoTileState,
    VideoGrid,
    ContentShare,
} from 'amazon-chime-sdk-component-library-react';
import { BaseProps } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Base';
import { Layout } from 'amazon-chime-sdk-component-library-react/lib/components/ui/VideoGrid';
import Video from './Video';

const fluidStyles = `
  height: 100%;
  width: 100%;
`;

const staticStyles = `
  display: flex;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 20vw;
  max-height: 30vh;
  height: auto;
  video {
    position: static;
  }
`;

interface Props extends BaseProps {
  /** The layout of the grid. */
  layout?: Layout;
}

const VideoDisplay: React.FC<Props> = ({
  layout = 'featured',
  ...rest }) => {
  const { tileId: featureTileId } = useFeaturedTileState();
  const { tiles } = useRemoteVideoTileState();
  const { tileId: contentTileId } = useContentShareState();
  const { isVideoEnabled } = useLocalVideo();

  // Set featured or standard layout
  const featured =
     layout === 'featured' ? !!featureTileId : !!contentTileId;

  // Calculate number of remote videos to display
  const remoteSize = tiles.length + (contentTileId ? 1 : 0);

  // Calculate overall grid size including local video
  const gridSize =
    (remoteSize > 1 && isVideoEnabled) ? remoteSize + 1 : remoteSize;

  return (
    <VideoGrid {...rest} size={gridSize} layout={featured ? 'featured' : null}>
      <ContentShare css="grid-area: ft;" />
      <Video isVideoEnabled={isVideoEnabled} remoteSize={remoteSize} layout={layout}/>
    </VideoGrid>
  );
};

export default VideoDisplay;
