// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';

import { DeviceLabelTriggerStatus, Modal, ModalBody, ModalHeader, useDeviceLabelTriggerStatus, } from 'amazon-chime-sdk-component-library-react';

import Card from '../components/Card/Card';
import { translated } from '../utils/TranslationUtil';

// Show permission modal when the user is Granting/Denied permissions
// Show nothing if permission is already granted or component loads on initial render

const GetPermissionModal = (status: DeviceLabelTriggerStatus) => {
    switch (status) {
        case DeviceLabelTriggerStatus.DENIED:
            return (
                <Modal
                    size="md"
                    rootId="device-permission-modal-root"
                >
                    <ModalHeader
                        title={translated('ui.Permission.Denied.CameraMicModal.Header.Title')}
                        displayClose={false}
                    />
                    <ModalBody>
                        <Card
                            title={translated('ui.Permission.Denied.CameraMicModal.Body.Title')}
                            description={
                                <>
                                    <p>
                                        {translated('ui.Permission.Denied.CameraMicModal.Body.Description')}
                                    </p>
                                    <br/>
                                    <p>
                                        {translated('ui.Permission.Denied.CameraMicModal.Body.Hint')}
                                    </p>
                                    <br/>
                                    <a href="https://support.google.com/chrome/answer/2693767">
                                        Chrome Help
                                    </a>
                                    <br/>
                                    <a href="https://support.apple.com/guide/iphone/control-access-to-hardware-features-iph168c4bbd5/ios">
                                        Safari Help
                                    </a>
                                </>
                            }
                        />
                    </ModalBody>
                </Modal>
            );
        case DeviceLabelTriggerStatus.IN_PROGRESS:
            return (
                <Modal
                    size="md"
                    rootId="device-permission-modal-root"
                >
                    <ModalHeader
                        title={translated('ui.Permission.InProgress.CameraMicModal.Header.Title')}
                        displayClose={false}
                    />
                    <ModalBody>
                        <Card
                            title={translated('ui.Permission.InProgress.CameraMicModal.Body.Title')}
                            description={
                                <>
                                    <p>
                                        {translated('ui.Permission.InProgress.CameraMicModal.Body.Description')}
                                    </p>
                                    <br/>
                                    <p>
                                        {translated('ui.Permission.InProgress.CameraMicModal.Body.Hint')}
                                    </p>
                                </>
                            }
                        />
                    </ModalBody>
                </Modal>
            );
        default:
            return null;

    }
}

const DevicePermissionPrompt = () => {
    const status = useDeviceLabelTriggerStatus();
    return GetPermissionModal(status);
};

export default DevicePermissionPrompt;
