// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { FC, useMemo } from 'react';
import { HashRouter as Router, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  NotificationProvider,
  GlobalStyles,
  LoggerProvider,
} from 'amazon-chime-sdk-component-library-react';

import { demoLightTheme, demoDarkTheme } from './theme/demoTheme';
import { AppStateProvider, useAppState } from './providers/AppStateProvider';
import ErrorProvider from './providers/ErrorProvider';
import Notifications from './containers/Notifications';
import MeetingProviderWrapper from './containers/MeetingProviderWrapper/MeetingProviderWrapper';
import meetingConfig from './meetingConfig';
import { JoinMeetingInfo } from './utils/api';

const App: FC = () => (
  <Router>
    <AppParams />
  </Router>
);

const AppParams: FC = () => {
  const [searchParams] = useSearchParams();

  const joinInfo = useMemo(() => {
    try {
      return JSON.parse(atob(searchParams.get('JoinInfo') || '')) as JoinMeetingInfo;
    } catch (err) {
      console.error('Could not parse JoinInfo')
      return undefined;
    }
  }, [searchParams]);

  return (
    <Meeting joinInfo={joinInfo} />
  )
}

interface MeetingProps {
  joinInfo: JoinMeetingInfo | undefined;
}

export const Meeting: FC<MeetingProps> = ({ joinInfo }) => {
  return (
    <LoggerProvider logger={meetingConfig.logger}>
      <AppStateProvider joinInfo={joinInfo}>
        <Theme>
          <NotificationProvider>
            {/* <Notifications /> */}
            <ErrorProvider>
              <MeetingProviderWrapper />
            </ErrorProvider>
          </NotificationProvider>
        </Theme>
      </AppStateProvider>
    </LoggerProvider>
  );
};

const Theme: React.FC = ({ children }) => {
  const { theme } = useAppState();

  return (
    <ThemeProvider theme={theme === 'light' ? demoLightTheme : demoDarkTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default App;
