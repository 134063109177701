import React from 'react';
import styled from 'styled-components';
import { Branding } from './Branding';
import { translated } from './meeting/utils/TranslationUtil';

//Styled Components - Consent Window
const ConsentWindow = styled.div`
    align-items: center;
    background-color: rgb(239, 248, 252);
    display: flex;
    justify-content: center;
    height: 100vh;
    position: fixed;
    width: 100vw;
    max-width: 1600px;
`;
const ConsentModal = styled.div`
    background-clip: padding-box;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px #0000001f, 
        0 6px 16px #00000014, 
        0 9px 28px 8px #0000000d;
    border-radius: 2px;
    max-width: 600px;
    padding: 25px;
    position: relative;

    @media (max-width: 750px) {
        margin: 25px;
    }
`;
//Consent Window - Header (Branding)
const ConsentHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;
//Consent Window - Body
const ConsentBody = styled.div`
`;
const ConsentBodyText = styled.p`
    font: 18px 'equiplight';
    margin-top: 0px;
    padding: 0px 20px;

    @media (max-width: 750px) {
        font: 14px 'equiplight';
        padding: 5px;
        margin-right: 15px;
    }
`;
//Consent Window - Footer (Buttons)
const ConsentFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
`;
const CancelButton = styled.button`
    background-color: #F1670D;
    border: 1px solid #F1670D;
    border-radius: 8px;
    height: 36px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    width: 125px;

    &:hover {
        background-color: #c74e00;
        border: 1px solid #c74e00;
        cursor: pointer;
    }
`;
const ConfirmButton = styled.button`
    background-color: #1998d5;
    border: 1px solid #1998d5;
    border-radius: 8px;
    height: 36px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-right: 15px;
    width: 125px;

    &:hover {
        background-color: #1476ad;
        border: 1px solid #1476ad;
        cursor: pointer;
    }
`;

export type VideoChatConsentProps = {
    accept: React.MouseEventHandler<HTMLButtonElement>;
    decline: React.MouseEventHandler<HTMLButtonElement>;
}

export function VideoChatConsent(props: VideoChatConsentProps) {
    return (
        <ConsentWindow>
            <ConsentModal>
                <ConsentHeader>
                    <Branding
                        header={translated('ui.Branding.header')}
                        subheader={translated('ui.Branding.subHeader')}
                    />
                </ConsentHeader>
                <ConsentBody>
                    <ConsentBodyText>
                        {translated('ui.Consent.paragraph1')}<br /><br />{translated('ui.Consent.paragraph2')}
                    </ConsentBodyText>
                </ConsentBody>
                <hr></hr>
                <ConsentFooter>
                    <ConfirmButton
                        onClick={props.accept}
                    >
                        {translated('ui.Consent.confirmButton')}
                    </ConfirmButton>
                    <CancelButton
                        onClick={props.decline}
                    >
                        {translated('ui.Consent.declineButton')}
                    </CancelButton>
                </ConsentFooter>
            </ConsentModal>
        </ConsentWindow>
    )
}
