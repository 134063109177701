// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ControlBarButton,
  Phone,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useLogger,
} from 'amazon-chime-sdk-component-library-react';

import { StyledP } from './Styled';
import { useAppState } from '../../providers/AppStateProvider';
import routes from '../../constants/routes';
import { VideoChatContext } from '../../../VideoChatNxUI';
import { translated } from '../../utils/TranslationUtil';

const EndMeetingControl: React.FC = () => {
  const logger = useLogger();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = (): void => setShowModal(!showModal);
  const { meetingId } = useAppState();
  const navigate = useNavigate();
  const { contextPath } = useContext(VideoChatContext);

  const endMeetingForAll = async (): Promise<void> => {
    try {
      if (meetingId) {
        navigate(`${contextPath}${routes.END}`);
      }
    } catch (e) {
      logger.error(`Could not end meeting: ${e}`);
    }
  };

  return (
    <>
      <ControlBarButton icon={<Phone />} onClick={toggleModal} label={translated('ui.EndMeetingControls.endMeetingLabel')} />
      {showModal && (
        <Modal size="md" onClose={toggleModal} rootId="modal-root">
          <ModalHeader title={translated('ui.EndMeetingControls.endMeetingModal')} />
          <ModalBody>
            <StyledP>
              {translated('ui.EndMeetingControls.endMeetingText')}
            </StyledP>
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={[
              <ModalButton
                key="end-meeting-for-all"
                onClick={endMeetingForAll}
                variant="primary"
                label={translated('ui.EndMeetingControls.endMeetingModal')}
                closesModal
              />,
              <ModalButton key="cancel-meeting-ending" variant="secondary" label={translated('ui.EndMeetingControls.endMeetingButton')} closesModal />,
            ]}
          />
        </Modal>
      )}
    </>
  );
};

export default EndMeetingControl;
