import React from 'react';
import ReactDOM from 'react-dom';
import { RingVideoChatNxUI } from './components/VideoChatNxUI';
import { HashRouter } from 'react-router-dom';

ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <RingVideoChatNxUI 
                contextPath=''
                enableVideoSharing
                showLocalVideo
                consentWindow={true}
            />
        </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
