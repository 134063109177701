// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';
import { Heading } from 'amazon-chime-sdk-component-library-react';
import MeetingJoinDetails from '../../containers/MeetingJoinDetails';
import { StyledLayout } from './Styled';
import DeviceSelection from '../../components/DeviceSelection/DeviceSelection';
import { Branding } from '../../../Branding';
import { translated } from '../../utils/TranslationUtil';
import DevicePermissionPrompt from '../../containers/DevicePermissionPrompt';

const DeviceSetup: React.FC = () => (
  <StyledLayout>
    <Branding
      header={translated('ui.Branding.header')}
      subheader={translated('ui.Branding.subHeader')}
    />
    <DevicePermissionPrompt />
    <MeetingJoinDetails />
    <Heading tag="h1" level={3} css="align-self: flex-start">
      {translated('ui.DeviceSetup.header')}
    </Heading>
    <DeviceSelection />
  </StyledLayout>
);

export default DeviceSetup;
