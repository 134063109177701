// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import styled from 'styled-components';

export const StyledLayout = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100%;
  max-width: 85rem;
  padding: 2rem;
  margin: auto;
  
  h1 { 
    margin: 10px auto 0px;
  }
  
  div:first-child { 
    margin-bottom: 10px;
  }
    
  @media (max-width: 760px) {
    border-right: unset;
    align-items: unset;
    justify-content: unset;
    flex-direction: column;

    h1 { 
        font-size: 24px;
    }
  }
`;
