// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext } from 'react';
import { UserActivityProvider } from 'amazon-chime-sdk-component-library-react';

import { StyledLayout, StyledContent } from './Styled';
import NavigationControl from '../../containers/Navigation/NavigationControl';
import { useNavigation } from '../../providers/NavigationProvider';
import MeetingControls from '../../containers/MeetingControls/MeetingControls';
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect';
import { MeetingMode, Layout } from '../../types/types';
import { VideoTileGridProvider } from '../../providers/VideoTileGridProvider/VideoTileGridProvider';
import { useAppState } from '../../providers/AppStateProvider';
import { DataMessagesProvider } from '../../providers/DataMessagesProvider/DataMessagesProvider';
import { VideoChatContext } from '../../../VideoChatNxUI';
import VideoDisplay from '../../components/VideoDisplay/VideoDisplay';

const MeetingView = (props: { mode: MeetingMode }) => {
  const { contextPath } = useContext(VideoChatContext);
  useMeetingEndRedirect(contextPath);
  const { showNavbar, showRoster, showChat } = useNavigation();
  const { layout } = useAppState();

  return (
    <UserActivityProvider>
      <DataMessagesProvider>
        <VideoTileGridProvider>
          <StyledLayout showNav={showNavbar} showRoster={showRoster} showChat={showChat}>
            <StyledContent>
              <VideoDisplay
                layout={layout === Layout.Gallery ? 'standard' : 'featured'}
                className="videos"
              />
              <MeetingControls />
            </StyledContent>
            <NavigationControl />
          </StyledLayout>
        </VideoTileGridProvider>
      </DataMessagesProvider>
    </UserActivityProvider>
  );
};

export default MeetingView;
