{
    "ui": {
        "Branding": {
            "header": "Neighbor Tools",
            "subHeader": "Video Conference"
        },
        "Consent": {
            "paragraph1": "I consent to using video call to allow the Ring Community Support agent to analyze and resolve my issue / query. See Ring's Privacy Notice for additional information.",
            "paragraph2": "I acknowledge that this is a one-way video call and the agent video will not be available. I understand that the agent screen will not be shared during the video call.",
            "confirmButton": "Accept",
            "declineButton": "Decline"
        },
        "CameraDevices": {
            "cameraHeader": "Camera source",
            "cameraDropdown": "No camera devices found",
            "previewHeader": "Video preview",
            "qualityHeader": "Video quality",
            "qualityDropdown": "Select video quality"
        },
        "DeviceSetup": {
            "header": "Device settings"
        },
        "EndMeetingControls": {
            "endMeetingButton": "Cancel",
            "endMeetingLabel": "Leave",
            "endMeetingModal": "End meeting",
            "endMeetingText": "Are you sure you want to end the meeting?"
        },
        "EndMeeting": {
            "header": "The meeting has ended"
        },
        "MeetingControls": {
            "audioLabel": "Speaker",
            "menuLabel": "Menu",
            "muteLabel": "Mute",
            "unmuteLabel": "Unmute",
            "mutedIconTitle": "Muted microphone",
            "unmutedIconTitle": "Microphone",
            "voiceFocusOffLabel": "Amazon Voice Focus enabled",
            "voiceFocusOnLabel": "Enable Amazon Voice Focus"
        },
        "MeetingJoinDetails": {
            "component": {
                "joinMeetingButton": "Join Meeting",
                "joinMeetingText": "Joining meeting as",
                "loadingText": "Loading..."
            },
            "modal": {
                "modalHeaderText": "Cannot refresh link",
                "cardTitle": "Video Conference",
                "cardDescription": "The meeting has ended",
                "cardSubtext1": "Redirecting in ",
                "cardSubtext2": " seconds ..."
            }
        },
        "MicrophoneActivity": {
            "microphoneActivityText": "Microphone activity"
        },
        "MicrophoneDevices": {
            "microphoneText": "Microphone source",
            "microphoneDropdown": "No microphone devices found"
        },
        "Permission": {
            "Denied": {
                "CameraMicModal": {
                    "Header": {
                        "Title": "Camera and Microphone Permission Denied"
                    },
                    "Body": {
                        "Title": "You must Allow Camera and Microphone permission",
                        "Description": "We are missing permissions to your microphone or camera.",
                        "Hint": "Please Allow Permissions to your Microphone and Camera then refresh the page."
                    }
                }
            },
            "InProgress": {
                "CameraMicModal": {
                    "Header": {
                        "Title": "Camera and Microphone Permission"
                    },
                    "Body": {
                        "Title": "Please Allow Camera and Microphone permission",
                        "Description": "In order to video chat, we need permissions to your microphone and camera.",
                        "Hint": "When the pop-up appears, choose Allow."
                    }
                }
            }
        },
        "SpeakerDevices": {
            "speakerText": "Test speakers",
            "sourceText": "Speaker source",
            "noSpeakerText": "No speaker devices found"
        },
        "VideoInputTransformation": {
            "blurLabel": "Enable Background Blur",
            "replacementLabel": "Enable Background Replacement"
        },
        "VideoTransform": {
            "header": "Video Transform Dropdown"
        }
    }
}
