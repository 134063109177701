{
    "ui": {
        "Branding": {
            "header": "Outils voisins",
            "subHeader": "Conférence vidéo"
        },
        "Consent": {
            "paragraph1": " Je consens à utiliser l'appel vidéo pour permettre à l'agent d'assistance de la communauté Ring d'analyser et de résoudre mon problème / Requête. Consultez l'avis de confidentialité de Ring pour plus d'informations.",
            "paragraph2": " Je reconnais qu'il s'agit d'un appel vidéo à sens unique et que la vidéo de l'agent ne sera pas disponible. Je comprends que l'écran de l'agent ne sera pas partagé pendant l'appel vidéo.",
            "confirmButton": "Accepter",
            "declineButton": "Décliner"
        },
        "CameraDevices": {
            "cameraHeader": "Source caméra",
            "cameraDropdown": "Aucune caméra trouvée",
            "previewHeader": "Prévisualisation vidéo",
            "qualityHeader": "Qualité de la vidéo",
            "qualityDropdown": "Sélectionnez la qualité vidéo"
        },
        "DeviceSetup": {
            "header": "Paramètres de l'appareil"
        },
        "EndMeetingControls": {
            "endMeetingButton": "Annuler",
            "endMeetingLabel": "Quitter",
            "endMeetingModal": "Terminer la réunion",
            "endMeetingText": "Êtes-vous sûr de vouloir mettre fin à la réunion?"
        },
        "EndMeeting": {
            "header": "La réunion est terminée"
        },
        "MeetingControls": {
            "audioLabel": "Haut-parleur",
            "menuLabel": "Menu",
            "muteLabel": "Muet",
            "unmuteLabel": "Rétablir le son",
            "mutedIconTitle": "Micro coupé",
            "unmutedIconTitle": "Microphone",
            "voiceFocusOffLabel": "Focalisation vocale Amazon activé",
            "voiceFocusOnLabel": " Activer focalisation vocale Amazon"
        },
        "MeetingJoinDetails": {
            "component": {
                "joinMeetingButton": "Rejoindre la réunion",
                "joinMeetingText": "Rejoindre la réunion en tant que",
                "loadingText": "Chargement..."
            },
            "modal": {
                "modalHeaderText": "Impossible d'actualiser le lien",
                "cardTitle": "Conférence vidéo",
                "cardDescription": "La réunion est terminée",
                "cardSubtext1": "Rediriger vers",
                "cardSubtext2": "Secondes ..."
            }
        },
        "MicrophoneActivity": {
            "microphoneActivityText": "Activité du microphone"
        },
        "MicrophoneDevices": {
            "microphoneText": "Source micro",
            "microphoneDropdown": "Aucun microphone trouvé"
        },
        "Permission": {
            "Denied": {
                "CameraMicModal": {
                    "Header": {
                        "Title": "Autorisation caméra et microphone refusée"
                    },
                    "Body": {
                        "Title": "Vous devez garantir l'autorisation de la caméra et du microphone",
                        "Description": "Nous manquons d'autorisations pour votre micro ou votre caméra.",
                        "Hint": "Veuillez autoriser l'accès pour votre microphone et votre caméra, puis actualisez la page."
                    }
                }
            },
            "InProgress": {
                "CameraMicModal": {
                    "Header": {
                        "Title": "Autorisation caméra et microphone"
                    },
                    "Body": {
                        "Title": " Veuillez autoriser l'accès de la caméra et le microphone",
                        "Description": "Pour pouvoir discuter par vidéo, nous avons besoin d'autorisations pour votre microphone et votre caméra.",
                        "Hint": "Lorsque le pop-up apparaît, choisissez Autoriser."
                    }
                }
            }
        },
        "SpeakerDevices": {
            "speakerText": "Tester les haut-parleurs",
            "sourceText": "Source de haut-parleur",
            "noSpeakerText": "Aucun appareil d'enceinte trouvé"
        },
        "VideoInputTransformation": {
            "blurLabel": "Activer le flou de fond",
            "replacementLabel": " Activer le remplacement du fond"
        },
        "VideoTransform": {
            "header": "Menu déroulant de transformation vidéo"
        }
    }
}
