import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useSensitiveParam = (key: string, redact = true) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [sensitiveValue, setSensitiveValue] = useState<string>();

    // read url parameters and clear them
    useEffect(() => {
        const searchParamsMap: { [key: string]: string } = {};
        searchParams.forEach((value, key) => searchParamsMap[key] = value);

        if (key in searchParamsMap) {
            setSensitiveValue(searchParamsMap[key]);
            if (redact) {
                delete searchParamsMap[key];
            }
            setSearchParams(searchParamsMap);
        }
    }, [searchParams, key]);

    return sensitiveValue;
};
