import styled from 'styled-components';
import Logo from '../images/Logo';
import React, { PropsWithChildren } from 'react';

const BrandingContainer = styled.div`
    padding-top: 10px;
    width: fit-content;

    @media (max-width: 750px) {
        width: 100%;
    }
`;
const BrandingDivider = styled.div`
    float: left;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #424242;
    height: 55px;
    width: 1px;
`;
const BrandingHeader = styled.p`
    font: 28px 'equipthin';
    color: #424242;
    float: left;
    margin-top: -5px;

    @media (max-width: 750px) {
        font: 24px 'equiplight';
    }

    @media (max-width: 450px) {
        font: 18px 'equiplight';
    }
`;
const BrandingIcon = styled.div`
    float: left;
    left: 20px;
    top: 10px;
    width: 90px;

    @media (max-width: 750px) {
        width: 75px;
    }
`;
const BrandingSubheader = styled.span`
    font: 18px 'equiplight';
    color: #1998d5;
    float: left;

    @media (max-width: 750px) {
        font: 16px 'equiplight';
    }
`;

type Props = PropsWithChildren<{
    header: string;
    subheader?: string;
}>;

export function Branding(props: Props) {
    const {
        header,
        subheader,
    } = props;

    return (
        <BrandingContainer>
            <BrandingIcon>
                <Logo />
            </BrandingIcon>
            <BrandingDivider />
            <BrandingHeader> {header} <br />
                <BrandingSubheader> {subheader} </BrandingSubheader>
            </BrandingHeader>
        </BrandingContainer>
    );
}
