// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext } from 'react';
import {
  ControlBar,
  AudioInputVFControl,
  AudioInputControl,
  AudioOutputControl,
  ControlBarButton,
  useUserActivityState,
  Dots,
  VideoInputControl,
} from 'amazon-chime-sdk-component-library-react';

import EndMeetingControl from '../EndMeetingControl/EndMeetingControl';
import { useNavigation } from '../../providers/NavigationProvider';
import { StyledControls } from './Styled';
import { useAppState } from '../../providers/AppStateProvider';
import { VideoFiltersCpuUtilization } from '../../types/types';
import VideoInputTransformControl from '../../components/VideoInputTransformationControl/VideoInputTransformationControl';
import { VideoChatContext } from '../../../VideoChatNxUI';
import { translated } from '../../utils/TranslationUtil';

const MeetingControls: React.FC = () => {
  const { toggleNavbar, closeRoster, showRoster } = useNavigation();
  const { isUserActive } = useUserActivityState();
  const { isWebAudioEnabled, videoTransformCpuUtilization } = useAppState();
  const videoTransformsEnabled = videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled;
  const { enableVideoSharing } = useContext(VideoChatContext);

  const handleToggle = (): void => {
    if (showRoster) {
      closeRoster();
    }
    toggleNavbar();
  };

  return (
    <StyledControls className="controls" active={!!isUserActive}>
      <ControlBar
        className="controls-menu"
        layout="undocked-horizontal"
        responsive
        showLabels
      >
        <ControlBarButton
          className="mobile-toggle"
          icon={<Dots />}
          onClick={handleToggle}
          label={translated('ui.MeetingControls.menuLabel')}
        />
        {isWebAudioEnabled ?
          <AudioInputVFControl
            muteLabel={translated('ui.MeetingControls.muteLabel')}
            unmuteLabel={translated('ui.MeetingControls.unmuteLabel')}
            mutedIconTitle={translated('ui.MeetingControls.mutedIconTitle')}
            unmutedIconTitle={translated('ui.MeetingControls.unmutedIconTitle')}
            voiceFocusOnLabel={translated('ui.MeetingControls.voiceFocusOnLabel')}
            voiceFocusOffLabel={translated('ui.MeetingControls.voiceFocusOffLabel')}
          /> :
          <AudioInputControl
            muteLabel={translated('ui.MeetingControls.muteLabel')}
            unmuteLabel={translated('ui.MeetingControls.unmuteLabel')}
            mutedIconTitle={translated('ui.MeetingControls.mutedIconTitle')}
            unmutedIconTitle={translated('ui.MeetingControls.unmutedIconTitle')}
          />
        }
        {enableVideoSharing &&
          (videoTransformsEnabled ? <VideoInputTransformControl /> : <VideoInputControl />)}
        <AudioOutputControl label={translated('ui.MeetingControls.audioLabel')} />
        <EndMeetingControl />
      </ControlBar>
    </StyledControls>
  );
};

export default MeetingControls;
