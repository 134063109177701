import React, { useContext } from 'react';

import { 
    FeaturedRemoteVideos,
    LocalVideo,
    RemoteVideos
} from 'amazon-chime-sdk-component-library-react';
import { BaseProps } from 'amazon-chime-sdk-component-library-react/lib/components/ui/Base';
import { VideoChatContext } from '../../../VideoChatNxUI';
import MeetingDetails from '../../containers/MeetingDetails/MeetingDetails';


interface VideoProps extends BaseProps {
    isVideoEnabled: boolean;
    remoteSize: number;
    layout: string;
}

const Video = (props: VideoProps) => {
    const { showLocalVideo } = useContext(VideoChatContext);

    if (showLocalVideo) {
        return (props.isVideoEnabled ? <LocalVideo/> : <MeetingDetails/>);
    } else {
        if (props.remoteSize === 0) {
            return <MeetingDetails/>;
        } else {
            return (props.layout === 'featured' ? <FeaturedRemoteVideos/> : <RemoteVideos/>);
        }
    }
};

export default Video;
