import { Heading } from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import { StyledLayout } from './Styled';
import { Branding } from '../../../Branding';
import { translated } from '../../utils/TranslationUtil';

const EndedMeeting: React.FC = () => (
    <StyledLayout>
        <Branding
            header={translated('ui.Branding.header')}
            subheader={translated('ui.Branding.subHeader')}
        />
        <Heading tag="h1" level={2} css="margin: auto; text-align: center;">
            {translated('ui.EndMeeting.header')}
        </Heading>
    </StyledLayout>
);

export default EndedMeeting;
