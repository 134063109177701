{
    "ui": {
        "Branding": {
            "header": "Herramientas del Vecino",
            "subHeader": "Videollamada"
        },
        "Consent": {
            "paragraph1": " Doy mi consentimiento para el uso de una videollamada, para permitir que el agente del Soporte Comunitario de Ring analice y resuelva mi problema/consulta. Consulte el Aviso de privacidad de Ring para obtener información adicional.",
            "paragraph2": " Reconozco que esta es una videollamada unidireccional, y que el video del agente no estará disponible. Entiendo que la pantalla del agente no se compartirá durante la videollamada.",
            "confirmButton": "Aceptar",
            "declineButton": " Rechazar"
        },
        "CameraDevices": {
            "cameraHeader": "Fuente de la cámara",
            "cameraDropdown": "No se ha encontrado ningún dispositivo con cámara",
            "previewHeader": " Vista previa del vídeo",
            "qualityHeader": " Calidad del video",
            "qualityDropdown": " Seleccionar calidad del vídeo"
        },
        "DeviceSetup": {
            "header": "encabezado"
        },
        "EndMeetingControls": {
            "endMeetingButton": "Cancelar",
            "endMeetingLabel": "Sair",
            "endMeetingModal": "Terminar llamada",
            "endMeetingText": " ¿Estás seguro de que quieres terminar la llamada?"
        },
        "EndMeeting": {
            "header": "encabezado"
        },
        "MeetingControls": {
            "audioLabel": "Altavoz",
            "menuLabel": " Menú",
            "muteLabel": "Silenciar",
            "unmuteLabel": " desactivar silencio",
            "mutedIconTitle": " Silenciar micrófono",
            "unmutedIconTitle": " Desactivar silencio del Micrófono",
            "voiceFocusOffLabel": "Amazon Voice Focus habilitado",
            "voiceFocusOnLabel": "Habilitar Amazon Voice Focus"
        },
        "MeetingJoinDetails": {
            "component": {
                "joinMeetingButton": "Unirse a la reunión",
                "joinMeetingText": "Unirse a la reunión como",
                "loadingText": "Cargando..."
            },
            "modal": {
                "modalHeaderText": " No se puede actualizar el enlace",
                "cardTitle": "Videollamada",
                "cardDescription": "Llamada terminada",
                "cardSubtext1": "Redirigir en ",
                "cardSubtext2": " segundos ..."
            }
        },
        "MicrophoneActivity": {
            "microphoneActivityText": " Actividad del micrófono"
        },
        "MicrophoneDevices": {
            "microphoneText": "Fuente del micrófono",
            "microphoneDropdown": "No se ha encontrado Ningún dispositivo con micrófono"
        },
        "Permission": {
            "Denied": {
                "CameraMicModal": {
                    "Header": {
                        "Title": " Permiso de Cámara y Micrófono Denegado"
                    },
                    "Body": {
                        "Title": "Necesita habilitar Cámara y Micrófono",
                        "Description": "No tenemos permiso para habilitar su cámara y micrófono.",
                        "Hint": " Activa permisos para su micrófono y cámara, luego actualice la página."
                    }
                }
            },
            "InProgress": {
                "CameraMicModal": {
                    "Header": {
                        "Title": "Permisos para Cámara y Micrófono"
                    },
                    "Body": {
                        "Title": "Favor activar permisos para micrófono y cámara",
                        "Description": "Para chatear con video, necesitamos permisos para activar su micrófono y cámara.",
                        "Hint": "En la ventana emergente, elija Permitir."
                    }
                }
            }
        },
        "SpeakerDevices": {
            "speakerText": "Prueba altavoz",
            "sourceText": "Fuente de altavoz",
            "noSpeakerText": " No se ha encontrado ningún dispositivo con altavoz"
        },
        "VideoInputTransformation": {
            "blurLabel": "Habilitar desenfoque de fondo",
            "replacementLabel": "Habilitar reemplazo de fondo"
        },
        "VideoTransform": {
            "header": "Menú Desplegable de transformación de video"
        }
    }
}
