import Polyglot from 'node-polyglot';
import locale_en from './locales/en.json';
import locale_es from './locales/es.json';
import locale_de from './locales/de.json';
import locale_fr from './locales/fr.json';
import locale_it from './locales/it.json';

const languageString =
    (navigator as any).userLanguage ||
    navigator.language ||
    (navigator as any).browserLanguage ||
    (navigator as any).systemLanguage || '';

const languageMap: { [key: string]: string } = {
    default: 'en',
    en: 'en',
    es: 'es',
    de: 'de',
    fr: 'fr',
    it: 'it',
};

const locale = languageMap[languageString.split(/[_-]/)[0].toLowerCase()] || languageMap.default;

const polyglot = new Polyglot({ locale });

polyglot.extend(locale_en, 'en');
polyglot.extend(locale_es, 'es');
polyglot.extend(locale_de, 'de');
polyglot.extend(locale_fr, 'fr');
polyglot.extend(locale_it, 'it');

export const translated = (phrase: string, options?: number | Polyglot.InterpolationOptions): string => {
    const localePhrase = `${locale}.${phrase}`;
    if (polyglot.has(localePhrase)) {
        return polyglot.t(localePhrase, options);
    }

    const defaultPhrase = `${languageMap.default}.${phrase}`;
    if (polyglot.has(defaultPhrase)) {
        return polyglot.t(defaultPhrase, options);
    }

    return phrase;
};
