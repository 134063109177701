// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';

import {
  Flex,
  Heading,
  PrimaryButton,
} from 'amazon-chime-sdk-component-library-react';

import { useAppState } from '../../providers/AppStateProvider';
// import { StyledList } from './Styled';
import { Branding } from '../../../Branding';
import { translated } from '../../utils/TranslationUtil';

const MeetingDetails = () => {
  const { meetingId, toggleTheme, theme, region } = useAppState();

  return (
    <Flex container layout="fill-space-centered">
      <Flex mb="2rem" mr={{ md: '2rem' }} px="1rem">
        <Branding
          header={translated('ui.Branding.header')}
          subheader={translated('ui.Branding.subHeader')}
        />
        {/*<Heading level={4} tag="h1" mb={2}>
          Ring Community Support | Video Conference
          </Heading>
          <StyledList>
          <div>
            <dt>Meeting ID</dt>
            <dd>{meetingId}</dd>
          </div>
          <div>
            <dt>Hosted region</dt>
            <dd>{region}</dd>
          </div>
        </StyledList>
        <PrimaryButton
          mt={4}
          label={theme === 'light' ? 'Dark mode' : 'Light mode'}
          onClick={toggleTheme}
        ></PrimaryButton> */}
      </Flex>
    </Flex>
  );
};

export default MeetingDetails;
