// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useState } from 'react';
import {
  SpeakerSelection,
  SecondaryButton,
  useAudioOutputs,
} from 'amazon-chime-sdk-component-library-react';

import TestSound from '../../../utils/TestSound';
import { translated } from '../../../utils/TranslationUtil';

const SpeakerDevices = () => {
  const { selectedDevice } = useAudioOutputs();
  const [selectedOutput, setSelectedOutput] = useState(selectedDevice);

  const handleChange = (deviceId: string): void => {
    setSelectedOutput(deviceId);
  };

  const handleTestSpeaker = () => {
    new TestSound(selectedOutput);
  };

  return (
    <div>
      <SpeakerSelection 
        label={translated('ui.SpeakerDevices.sourceText')}
        onChange={handleChange} 
        notFoundMsg={translated('ui.SpeakerDevices.noSpeakerText')}
      />
      <SecondaryButton label={translated('ui.SpeakerDevices.speakerText')} onClick={handleTestSpeaker} />
    </div>
  );
};

export default SpeakerDevices;
