// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DeviceLabels,
    Flex,
    Label,
    MeetingManagerJoinOptions,
    Modal,
    ModalBody,
    ModalHeader,
    PrimaryButton,
    useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';

import routes from '../constants/routes';
import Card from '../components/Card/Card';
import { useAppState } from '../providers/AppStateProvider';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import meetingConfig from '../meetingConfig';
import { MeetingMode } from '../types/types';
import { AttendeeResponse } from 'amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types';
import { JoinMeetingInfo } from '../utils/api';
import { VideoChatContext } from '../../VideoChatNxUI';
import { translated } from '../utils/TranslationUtil';

const createGetAttendeeCallback = (joinInfo: JoinMeetingInfo) =>
    async (chimeAttendeeId: string): Promise<AttendeeResponse> => ({
        name: joinInfo.AttendeeMap[chimeAttendeeId],
    });

const MeetingJoinDetails = () => {
    const meetingManager = useMeetingManager();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    let [redirectTime, setRedirectTime] = useState(10);
    const {
        meetingId,
        localUserName,
        joinInfo,
        enableSimulcast,
        priorityBasedPolicy,
        keepLastFrameWhenPaused,
        meetingMode,
        isWebAudioEnabled,
        setRegion,
        setMeetingMode,
    } = useAppState();
    const { contextPath } = useContext(VideoChatContext);

    const startMeeting = async () => {
        setIsLoading(true);

        meetingManager.getAttendee = createGetAttendeeCallback(joinInfo);

        try {
            if (!joinInfo) {
                throw new Error('Missing meeting info');
            }

            const meetingSessionConfiguration = new MeetingSessionConfiguration(joinInfo?.Meeting, joinInfo?.Attendee);
            if (
                meetingConfig.postLogger &&
                meetingSessionConfiguration.meetingId &&
                meetingSessionConfiguration.credentials &&
                meetingSessionConfiguration.credentials.attendeeId
            ) {
                const existingMetadata = meetingConfig.postLogger.metadata;
                meetingConfig.postLogger.metadata = {
                    ...existingMetadata,
                    meetingId: meetingSessionConfiguration.meetingId,
                    attendeeId: meetingSessionConfiguration.credentials.attendeeId,
                };
            }

            setRegion(joinInfo.Meeting.MediaRegion);
            meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = enableSimulcast;
            if (priorityBasedPolicy) {
                meetingSessionConfiguration.videoDownlinkBandwidthPolicy = priorityBasedPolicy;
            }
            meetingSessionConfiguration.keepLastFrameWhenPaused = keepLastFrameWhenPaused;
            const options: MeetingManagerJoinOptions = {
                deviceLabels: meetingMode === MeetingMode.Spectator ? DeviceLabels.None : DeviceLabels.AudioAndVideo,
                enableWebAudio: isWebAudioEnabled,
            };

            await meetingManager.join(meetingSessionConfiguration, options);
            setMeetingMode(MeetingMode.Attendee);

            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError(error.message);
            redirect();
        }
    };

    const handleJoinMeeting = async () => {
        setIsLoading(true);

        try {
            await meetingManager.start();
            setIsLoading(false);
            navigate(`${contextPath}${routes.MEETING}/${meetingId}`);
        } catch (error: any) {
            setIsLoading(false);
            setError(error.message);
        }
    };

    useEffect(() => {
        startMeeting();
    }, []);

    /**
     * Redirecting the user in case of error so that when they click the link on the email it will open a new tab
     */
    const redirect = () => {
        const interval = setInterval(() => {
            setRedirectTime(redirectTime -= 1);
        }, 1000);
        const timer = setTimeout(() => {
            clearTimeout(timer);
            clearInterval(interval);
            window.open('https://support.ring.com', '_self');
        }, 10000);
    };

    return (
        <>
            <Flex container alignItems="center" flexDirection="column">
                <PrimaryButton
                    label={isLoading ? translated('ui.MeetingJoinDetails.component.loadingText') : translated('ui.MeetingJoinDetails.component.joinMeetingButton')}
                    onClick={handleJoinMeeting}
                />
                <Label style={{ margin: '.75rem 0 0 0' }}>
                    {translated('ui.MeetingJoinDetails.component.joinMeetingText')} <b>{localUserName}</b>
                </Label>
            </Flex>
            {error && (
                <Modal size="lg" id="redirect-modal"
                    onClose={() => window.open('https://support.ring.com', '_self')}>
                    <ModalHeader title={translated('ui.MeetingJoinDetails.modal.modalHeaderText')} />
                    <ModalBody>
                        <Card
                            title={translated('ui.MeetingJoinDetails.modal.cardTitle')}
                            description={translated('ui.MeetingJoinDetails.modal.cardDescription')}
                            smallText={translated('ui.MeetingJoinDetails.modal.cardSubtext1') + redirectTime + translated('ui.MeetingJoinDetails.modal.cardSubtext2')}
                        />
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

export default MeetingJoinDetails;
