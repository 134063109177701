{
    "ui": {
        "Branding": {
            "header": "Nachbar-Instrument",
            "subHeader": "Videokonferenz"
        },
        "Consent": {
            "paragraph1": "Ich stimme der Verwendung von Videoanrufen zu, damit der Ring Community Support Agent mein Anliegen analysieren und lösen kann. Weitere Informationen sind enthalten in der Datenschutzerklärung.",
            "paragraph2": "Ich bestätige, dass dies ein einseitiger Videoanruf ist, und mir ist bekannt, dass weder der Bildschirm noch die Kamera des Agenten verfügbar ist.",
            "confirmButton": "Annehmen",
            "declineButton": "Ablehnen"
        },
        "CameraDevices": {
            "cameraHeader": "Kameraquelle",
            "cameraDropdown": "Kein Gerät mit Kamera gefunden",
            "previewHeader": "Videovorschau",
            "qualityHeader": "Videoqualität",
            "qualityDropdown": "Wähle die Videoqualität"
        },
        "DeviceSetup": {
            "header": "Geräte Einstellungen"
        },
        "EndMeetingControls": {
            "endMeetingButton": "Absagen",
            "endMeetingLabel": "verlassen",
            "endMeetingModal": "Sitzung beenden",
            "endMeetingText": "Möchten Sie das Meeting wirklich beenden?"
        },
        "EndMeeting": {
            "header": "Das Treffen ist beendet"
        },
        "MeetingControls": {
            "audioLabel": "Lautsprecher",
            "menuLabel": "Menü",
            "muteLabel": "Stummschalten",
            "unmuteLabel": "Ton einschalten",
            "mutedIconTitle": "Mikrofon stummgeschalten",
            "unmutedIconTitle": "Mikrofon",
            "voiceFocusOffLabel": "Amazon Voice Focus enabled",
            "voiceFocusOnLabel": "Enable Amazon Voice Focus"
        },
        "MeetingJoinDetails": {
            "component": {
                "joinMeetingButton": "Meeting beitreten",
                "joinMeetingText": "Meeting beitreten als",
                "loadingText": "Wird geladen..."
            },
            "modal": {
                "modalHeaderText": "Link kann nicht aktualisiert werden",
                "cardTitle": "Videokonferenz",
                "cardDescription": "Das Meeting wurde beendet",
                "cardSubtext1": "Weiterleitung in ",
                "cardSubtext2": " Sekunden..."
            }
        },
        "MicrophoneActivity": {
            "microphoneActivityText": "Mikrofonaktivität"
        },
        "MicrophoneDevices": {
            "microphoneText": "Mikrofonquelle",
            "microphoneDropdown": "Kein Mikrofongerät gefunden"
        },
        "SpeakerDevices": {
            "speakerText": "Lautsprecher testen",
            "sourceText": "Sprecherquelle",
            "noSpeakerText": "Kein Spachgerät gefunden"
        },
        "VideoInputTransformation": {
            "blurLabel": "Hintergrund verwischen ermöglichen",
            "replacementLabel": "Hintergrundersatz ermöglichen"
        },
        "VideoTransform": {
            "header": "Videotransform Menü"
        }
    }
}
