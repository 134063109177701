import React, { useMemo, useState } from 'react';
import { useSensitiveParam } from '../utils/params';
import { Meeting } from './meeting/app';
import { JoinMeetingInfo } from './meeting/utils/api';
import { VideoChatConsent } from './VideoChatConsent';
import styled from 'styled-components';
import './font-face.css';

//Styled Components
const MeetingWrapper = styled.div`
    max-width: 1600px;
    margin: 0 auto;
`;

export type VideoChatProps = {
    contextPath: string;
    enableVideoSharing: boolean;
    showLocalVideo: boolean;
    consentWindow: boolean;
}

export type VideoChatConsent = {
    confirm: () => void;
}

export const VideoChatContext = React.createContext<VideoChatProps>(null);

export function RingVideoChatNxUI(props: VideoChatProps) {

    const [consent, setConsent] = useState(false);
    const joinInfoParam = useSensitiveParam('JoinInfo', false);
    const joinInfo = useMemo(() => {
        try {
            return JSON.parse(atob(joinInfoParam || '')) as JoinMeetingInfo;
        } catch (err) {
            console.error('Could not parse JoinInfo')
            return undefined;
        }
    }, [joinInfoParam]);

    //Video Chat Consent - Accept
    const acceptClick = () => {
        setConsent(true);
    };
    //Video Chat Consent - Decline
    const declineClick = () => {
        //Tab will not close in mobile in most cases, navigate to Ring's support site instead
        window.open('https://support.ring.com', '_self');
    };

    return (
        <VideoChatContext.Provider value={props}>
            {props.consentWindow ?
                <MeetingWrapper>
                    {consent ?
                        <Meeting joinInfo={joinInfo}/> :
                        <VideoChatConsent
                            accept={acceptClick}
                            decline={declineClick}
                        />
                    }
                </MeetingWrapper> :
                <Meeting joinInfo={joinInfo}/>
            }
        </VideoChatContext.Provider>
    )
}
